import AdminAppealForm from "../components/AdminForms/AdminAppealForm"


const AdminAppealFormContainer = () => {

  return (
    <>
      <AdminAppealForm />
    </>
  )
}

export default AdminAppealFormContainer