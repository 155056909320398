import AdminAppealFormContainer from "../containers/AdminAppealFormContainer"



const AppealAdminPage = () => (
  <div className="AppealAdminPage page" style={{width: '100%', display:'flex', justifyContent:'center'}}>
    <AdminAppealFormContainer />
  </div>
)

export default AppealAdminPage