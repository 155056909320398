import SearchBlock from "../components/SearchBlock/SearchBlock"


const SearchBlockContainer = () => {

  return (
    <>
      <SearchBlock />
    </>
  )
}

export default SearchBlockContainer