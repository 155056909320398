export const SET_REGIONS_LIST = "SET_REGIONS_LIST"
export const SET_LOCALE = "SET_LOCALE"
export const SET_DISTRICTS_LIST = "SET_DISTRICTS_LIST"
export const SET_SENDING_STATUS = "SET_SENDING_STATUS"
export const SET_IS_AUTH = "SET_IS_AUTH"
export const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE"
export const SET_PASSWORD_IS_WRONG = "SET_PASSWORD_IS_WRONG"
export const SET_APPEALS_LIST = "SET_APPEALS_LIST"
export const SET_ADVICES_LIST = "SET_ADVICES_LIST"
export const SET_PAGE_COUNT = "SET_PAGE_COUNT"
export const SET_ADVICE_DETAIL = "SET_ADVICE_DETAIL"
export const SET_APPEAL_DETAIL = "SET_APPEAL_DETAIL"
export const SET_LOADER = "SET_LOADER"